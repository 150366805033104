<template>
  <el-header class="header">
    <div class="leftPart">
      <div class="imgWrap">
        <img src="../../assets/img/logo1.png" class="logo" alt="logo" />

        <span v-if="PLATFORM === 'service'" class="text">{{
          $t('loginPage.customerPlatform')
        }}</span>
        <span v-else class="text">{{ $t('yl') }}</span>
      </div>

      <div class="nav">
        <template v-for="item in headerLink">
          <router-link
            class="link-li"
            :class="currentPage === item.name && 'is-check'"
            :key="item.name"
            :to="{ name: (item && item.name) || 'home' }"
          >
            {{ item.title }}
          </router-link>
        </template>
      </div>
    </div>

    <div class="rightPart">
      <!-- <el-badge v-if="haveMessage" is-dot class="alertWrap">
        <icon-svg
          @click="$router.push('/systemManagement/alertManagement')"
          icon-class="iconsmart-alerts"
          class-name="icons iconalert"
        />
      </el-badge> -->
      <el-link style="margin-right:6px" @click="toNewVersion" type="info">{{
        $t('newEdition')
      }}</el-link>
      <icon-svg icon-class="iconshensetouxiang" class-name="icons" />

      <el-menu
        default-active="1"
        class="el-menu-demo"
        mode="horizontal"
        background-color="#515151"
        text-color="#fff"
      >
        <el-submenu index="1">
          <template slot="title">{{ title }}</template>
          <el-menu-item @click="visible = true">{{ $t('changePassword') }}</el-menu-item>
          <el-menu-item @click="logout">{{ $t('signOut') }}</el-menu-item>
        </el-submenu>
        <span v-if="title" class="daysRemaining">{{ daysRemaining }}</span>
      </el-menu>
    </div>
    <password-modal @logout="logout" :visible.sync="visible" v-if="visible" />
  </el-header>
</template>

<script>
import passwordModal from './components/passwordModal'
import { BASE_URL, PLATFORM } from '@/lib/constant'

export default {
  data() {
    return {
      PLATFORM,
      title: '',
      haveMessage: true,
      visible: false
    }
  },
  async created() {
    this.innitSocket()

    if (this.username) {
      this.title = this.username
    } else {
      try {
        const res = await this.$api.systemManagement.userCurrent()
        this.title = res.result.accountCode || 'admin'
        this.$store.commit('SET_USERINFO', res.result)
      } catch (e) {
        console.log(e)
      }
    }
  },
  computed: {
    username() {
      return this.$store.state.username
    },
    currentPage() {
      return this.$store.state.currentPage
    },
    daysRemaining() {
      return sessionStorage.getItem('daysRemaining')
    },
    headerLink() {
      const arr = JSON.parse(JSON.stringify(this.$store.state.headerLink))

      arr.unshift({
        list: [],
        name: 'home',
        title: '首页'
      })
      return arr
    }
  },
  components: {
    passwordModal
  },
  methods: {
    toNewVersion() {
      sessionStorage.removeItem('oldStyle')
      location.reload()
    },
    innitSocket() {
      // var token = this.$utils.getCookie('loh-auth-token')
      // var api = `ws://${BASE_URL}/lohsvc/real-time?token=${token}`
      // var ws = new WebSocket(api)
      // ws.onopen = e => {
      //   console.log('websocket has connected!')
      // }
      // ws.onmessage = e => {
      //   console.log(e.data)
      // }
      // ws.onclose = e => {
      //   console.log('websocket has disconnected!')
      // }
      // ws.onerror = e => {
      //   console.log(e)
      // }
    },
    async logout() {
      try {
        const res = await this.$api.common.logout()

        if (res.code === 'OK') {
          localStorage.clear()
          sessionStorage.clear()
          this.$store.state.headerLink = []
          this.$router.push('/login')
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  background: #515151;
  display: flex;
  padding: 0 120px;
  box-shadow: 0px 5px 5px 0px #e9e9e9;

  .leftPart {
    display: flex;
    flex: 1;
  }

  .rightPart {
    display: flex;
    align-items: center;

    .alertWrap {
      margin-right: 30px;
      cursor: pointer;
    }

    .icons {
      width: 30px;
      height: 30px;
    }

    .iconalert {
      width: 20px;
      height: 20px;
      color: #fff;

      &:hover {
        color: #46adff;
      }
    }

    ::v-deep .el-menu.el-menu--horizontal {
      border-bottom: 0;
      position: relative;
      top: -6px;

      .daysRemaining {
        position: absolute;
        bottom: 5px;
        font-size: 12px;
        color: #85d719;
        right: 12px;
        // width: 50px;
        text-align: center;
      }
    }

    ::v-deep .el-submenu__title {
      padding: 0 0 0 11px;
      border-bottom: 0;
      i {
        color: #ccc;
        margin-top: -2px;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }

  .imgWrap {
    display: flex;
    align-items: center;
    .logo {
      height: 24px;
      vertical-align: middle;
    }
    .text {
      color: #fff;
      font-size: 22px;
      font-weight: bold;
      margin-left: 10px;
    }
  }

  .nav {
    margin-left: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .link-li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 14px;
    color: #fff;
    margin: 0 8px;
    width: 92px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;

    &:hover {
      background-color: rgb(65, 65, 65);
    }

    &.is-check {
      font-size: 14px;
      font-weight: 700;
      position: relative;
      color: #86d524;
      &:after {
        content: '';
        width: 58px;
        position: absolute;
        height: 3px;
        background: #86d524;
        bottom: 0;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          left: 17px;
        }
      }
    }

    a {
      transition: all 0.2s;
      color: #1f1e1d;
      display: block;
      padding: 0 10px;
    }
  }
}
</style>

<!--[if IE]>
  <style>
    .test {
      color: red;
    }
  </style>
<![endif]-->

<template>
  <el-button round v-bind="$attrs" v-on="$listeners">
    <icon-svg v-if="icon" :icon-class="icon" :class-name="`button-icon ${className}`" />
    <slot></slot>
  </el-button>
</template>

<script>
export default {
  name: 'c-button',
  props: {
    icon: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.button-icon {
  width: 1em;
  height: 1em;
  margin-right: 5px;
  vertical-align: -0.15em;
  color: #fff;
}
</style>

export const countryList = [
    {
        "value": "0",
        "code": "AF",
        "en": "Afghanistan",
        "zn": "阿富汗"
    },
    {
        "value": "0",
        "code": "AL",
        "en": "Albania",
        "zn": "阿尔巴尼亚"
    },
    {
        "value": "0",
        "code": "AD",
        "en": "Andorra",
        "zn": "安道尔"
    },
    {
        "value": "0",
        "code": "AO",
        "en": "Angola",
        "zn": "安哥拉"
    },
    {
        "value": "0",
        "code": "AI",
        "en": "Angola",
        "zn": "安圭拉"
    },
    {
        "value": "0",
        "code": "AQ",
        "en": "Antarctica",
        "zn": "南极洲"
    },
    {
        "value": "0",
        "code": "AG",
        "en": "Ntigua and Barbuda",
        "zn": "安提瓜和巴布达"
    },
    {
        "value": "0",
        "code": "AR",
        "en": "Argentina",
        "zn": "阿根廷"
    },
    {
        "value": "0",
        "code": "AM",
        "en": "Armenia",
        "zn": "亚美尼亚"
    },
    {
        "value": "0",
        "code": "AW",
        "en": "Aruba",
        "zn": "阿鲁巴"
    },
    {
        "value": "0",
        "code": "AU",
        "en": "Australia",
        "zn": "澳大利亚"
    },
    {
        "value": "0",
        "code": "AT",
        "en": "Austria",
        "zn": "奥地利"
    },
    {
        "value": "0",
        "code": "AZ",
        "en": "Azerbaijan",
        "zn": "阿塞拜疆"
    },
    {
        "value": "0",
        "code": "AE",
        "en": "United Arab Emirates",
        "zn": "阿联酋"
    },
    {
        "value": "0",
        "code": "BS",
        "en": "Bahamas",
        "zn": "巴哈马"
    },
    {
        "value": "0",
        "code": "BH",
        "en": "Bahrain",
        "zn": "巴林"
    },
    {
        "value": "0",
        "code": "BD",
        "en": "Bangladesh",
        "zn": "孟加拉"
    },
    {
        "value": "0",
        "code": "BB",
        "en": "Barbados",
        "zn": "巴巴多斯"
    },
    {
        "value": "0",
        "code": "BY",
        "en": "White Russia",
        "zn": "白俄罗斯"
    },
    {
        "value": "0",
        "code": "BZ",
        "en": "Belize",
        "zn": "伯利兹"
    },
    {
        "value": "0",
        "code": "BE",
        "en": "Belgium",
        "zn": "比利时"
    },
    {
        "value": "0",
        "code": "BJ",
        "en": "Benin",
        "zn": "贝宁"
    },
    {
        "value": "0",
        "code": "BM",
        "en": "Bermuda",
        "zn": "百慕大"
    },
    {
        "value": "0",
        "code": "BT",
        "en": "Bhutan",
        "zn": "不丹"
    },
    {
        "value": "0",
        "code": "BO",
        "en": "Bolivia",
        "zn": "玻利维亚"
    },
    {
        "value": "0",
        "code": "BA",
        "en": "Bosnia Hercegovina",
        "zn": "波黑"
    },
    {
        "value": "0",
        "code": "BW",
        "en": "Botswana",
        "zn": "博茨瓦纳"
    },
    {
        "value": "0",
        "code": "BV",
        "en": "Bouvet Island",
        "zn": "布维岛"
    },
    {
        "value": "0",
        "code": "BR",
        "en": "Brazil",
        "zn": "巴西"
    },
    {
        "value": "0",
        "code": "BN",
        "en": "Brunei Darussalam",
        "zn": "文莱"
    },
    {
        "value": "0",
        "code": "BG",
        "en": "Bulgaria",
        "zn": "保加利亚"
    },
    {
        "value": "0",
        "code": "BF",
        "en": "Burkina Faso",
        "zn": "布其纳法索"
    },
    {
        "value": "0",
        "code": "BI",
        "en": "Burundi",
        "zn": "布隆迪"
    },
    {
        "value": "0",
        "code": "CM",
        "en": "Cameroon",
        "zn": "喀麦隆"
    },
    {
        "value": "0",
        "code": "CA",
        "en": "Canada",
        "zn": "加拿大"
    },
    {
        "value": "0",
        "code": "CV",
        "en": "Cape Verde,Republic of",
        "zn": "佛得角"
    },
    {
        "value": "0",
        "code": "CF",
        "en": "The Central African Republic",
        "zn": "中非共和国"
    },
    {
        "value": "0",
        "code": "CL",
        "en": "Chile",
        "zn": "智利"
    },
    {
        "value": "0",
        "code": "CN",
        "en": "China",
        "zn": "中国"
    },
    {
        "value": "0",
        "code": "CX",
        "en": "Christmas Island",
        "zn": "圣诞岛"
    },
    {
        "value": "0",
        "code": "CC",
        "en": "COCOS Islands",
        "zn": "可可岛"
    },
    {
        "value": "0",
        "code": "CO",
        "en": "Colombia",
        "zn": "哥伦比亚"
    },
    {
        "value": "0",
        "code": "CH",
        "en": "Switzerland",
        "zn": "瑞士"
    },
    {
        "value": "0",
        "code": "CG",
        "en": "Congo",
        "zn": "刚果"
    },
    {
        "value": "0",
        "code": "CK",
        "en": "Cook Island",
        "zn": "库克群岛"
    },
    {
        "value": "0",
        "code": "CR",
        "en": "Costa rica",
        "zn": "哥斯达黎加"
    },
    {
        "value": "0",
        "code": "CI",
        "en": "Lvory Coast",
        "zn": "象牙海岸"
    },
    {
        "value": "0",
        "code": "CU",
        "en": "Cuba",
        "zn": "古巴"
    },
    {
        "value": "0",
        "code": "CY",
        "en": "Cyprus",
        "zn": "塞浦路斯"
    },
    {
        "value": "0",
        "code": "CZ",
        "en": "Czech Republic",
        "zn": "捷克共和国"
    },
    {
        "value": "0",
        "code": "DK",
        "en": "Denmark",
        "zn": "丹麦"
    },
    {
        "value": "0",
        "code": "DJ",
        "en": "Djibouti",
        "zn": "吉布提"
    },
    {
        "value": "0",
        "code": "DM",
        "en": "Gominica",
        "zn": "多明哥"
    },
    {
        "value": "0",
        "code": "DE",
        "en": "Grmany",
        "zn": "德国"
    },
    {
        "value": "0",
        "code": "DO",
        "en": "Dominica",
        "zn": "多米尼加"
    },
    {
        "value": "0",
        "code": "DZ",
        "en": "Algeria",
        "zn": "阿尔及利亚"
    },
    {
        "value": "0",
        "code": "EC",
        "en": "Ecuador",
        "zn": "厄瓜多尔"
    },
    {
        "value": "0",
        "code": "EG",
        "en": "Egypt",
        "zn": "埃及"
    },
    {
        "value": "0",
        "code": "EH",
        "en": "West Sahara",
        "zn": "西撒哈拉"
    },
    {
        "value": "0",
        "code": "ES",
        "en": "Spain",
        "zn": "西班牙"
    },
    {
        "value": "0",
        "code": "EE",
        "en": "Estonia",
        "zn": "爱沙尼亚"
    },
    {
        "value": "0",
        "code": "ET",
        "en": "Ethiopia",
        "zn": "埃塞俄比亚"
    },
    {
        "value": "0",
        "code": "FJ",
        "en": "Fiji",
        "zn": "斐济"
    },
    {
        "value": "0",
        "code": "FK",
        "en": "Falkland Islands",
        "zn": "福克兰群岛"
    },
    {
        "value": "0",
        "code": "FI",
        "en": "Finland",
        "zn": "芬兰"
    },
    {
        "value": "0",
        "code": "FR",
        "en": "France",
        "zn": "法国"
    },
    {
        "value": "0",
        "code": "FM",
        "en": "Micronesia",
        "zn": "密克罗尼西亚"
    },
    {
        "value": "0",
        "code": "GA",
        "en": "Gabon",
        "zn": "加蓬"
    },
    {
        "value": "0",
        "code": "GQ",
        "en": "Equatorial Guinea",
        "zn": "赤道几内亚"
    },
    {
        "value": "0",
        "code": "GF",
        "en": "French Guiana",
        "zn": "法属圭亚那"
    },
    {
        "value": "0",
        "code": "GM",
        "en": "Gambia",
        "zn": "冈比亚"
    },
    {
        "value": "0",
        "code": "GE",
        "en": "Georgia",
        "zn": "格鲁吉亚"
    },
    {
        "value": "0",
        "code": "GH",
        "en": "Ghana",
        "zn": "加纳"
    },
    {
        "value": "0",
        "code": "GI",
        "en": "Gibraltar",
        "zn": "直布罗陀"
    },
    {
        "value": "0",
        "code": "GR",
        "en": "Greece",
        "zn": "希腊"
    },
    {
        "value": "0",
        "code": "GL",
        "en": "Greenland",
        "zn": "格陵兰"
    },
    {
        "value": "0",
        "code": "GB",
        "en": "United Kingdom",
        "zn": "英国"
    },
    {
        "value": "0",
        "code": "GD",
        "en": "Grenada",
        "zn": "格林纳达"
    },
    {
        "value": "0",
        "code": "GP",
        "en": "Guadeloupe",
        "zn": "瓜德罗普"
    },
    {
        "value": "0",
        "code": "GU",
        "en": "Guam",
        "zn": "关岛"
    },
    {
        "value": "0",
        "code": "GT",
        "en": "Guatemala",
        "zn": "危地马拉"
    },
    {
        "value": "0",
        "code": "GN",
        "en": "Guinea",
        "zn": "几内亚"
    },
    {
        "value": "0",
        "code": "GW",
        "en": "Guinea-Bissau",
        "zn": "几内亚比绍"
    },
    {
        "value": "0",
        "code": "GY",
        "en": "Guyana",
        "zn": "圭亚那"
    },
    {
        "value": "0",
        "code": "HR",
        "en": "Croatia",
        "zn": "克罗地亚"
    },
    {
        "value": "0",
        "code": "HT",
        "en": "Haiti",
        "zn": "海地"
    },
    {
        "value": "0",
        "code": "HN",
        "en": "Honduras",
        "zn": "洪都拉斯"
    },
    {
        "value": "0",
        "code": "HK",
        "en": "Hong Kong",
        "zn": "中国香港"
    },
    {
        "value": "0",
        "code": "HU",
        "en": "Hungary",
        "zn": "匈牙利"
    },
    {
        "value": "0",
        "code": "IS",
        "en": "Iceland",
        "zn": "冰岛"
    },
    {
        "value": "0",
        "code": "IN",
        "en": "India",
        "zn": "印度"
    },
    {
        "value": "0",
        "code": "ID",
        "en": "Indonesia",
        "zn": "印度尼西亚"
    },
    {
        "value": "0",
        "code": "IR",
        "en": "Iran",
        "zn": "伊朗"
    },
    {
        "value": "0",
        "code": "IQ",
        "en": "Iraq",
        "zn": "伊拉克"
    },
    {
        "value": "0",
        "code": "IO",
        "en": "British Indian Ocean Territory",
        "zn": "英联邦的印度洋领域"
    },
    {
        "value": "0",
        "code": "IE",
        "en": "Ireland",
        "zn": "爱尔兰"
    },
    {
        "value": "0",
        "code": "IL",
        "en": "Israel",
        "zn": "以色列"
    },
    {
        "value": "0",
        "code": "IT",
        "en": "Italy",
        "zn": "意大利"
    },
    {
        "value": "0",
        "code": "JM",
        "en": "Jamaica",
        "zn": "牙买加"
    },
    {
        "value": "0",
        "code": "JP",
        "en": "Japan",
        "zn": "日本"
    },
    {
        "value": "0",
        "code": "JO",
        "en": "Jordan",
        "zn": "约旦"
    },
    {
        "value": "0",
        "code": "KZ",
        "en": "Kazakstan",
        "zn": "哈萨克斯坦"
    },
    {
        "value": "0",
        "code": "KE",
        "en": "Kenya",
        "zn": "肯尼亚"
    },
    {
        "value": "0",
        "code": "KI",
        "en": "Kiribati",
        "zn": "基里巴斯"
    },
    {
        "value": "0",
        "code": "KP",
        "en": "North Korea",
        "zn": "朝鲜"
    },
    {
        "value": "0",
        "code": "KR",
        "en": "Korea",
        "zn": "韩国"
    },
    {
        "value": "0",
        "code": "KH",
        "en": "Cambodia",
        "zn": "柬埔寨"
    },
    {
        "value": "0",
        "code": "KM",
        "en": "Comoros",
        "zn": "科摩罗"
    },
    {
        "value": "0",
        "code": "KW",
        "en": "kuwait",
        "zn": "科威特"
    },
    {
        "value": "0",
        "code": "KG",
        "en": "Kyrgyzstan",
        "zn": "吉尔吉斯斯坦"
    },
    {
        "value": "0",
        "code": "KY",
        "en": "Cayman Islands",
        "zn": "开曼群岛"
    },
    {
        "value": "0",
        "code": "LA",
        "en": "Laos",
        "zn": "老挝"
    },
    {
        "value": "0",
        "code": "LK",
        "en": "Sri Lanka",
        "zn": "斯里兰卡"
    },
    {
        "value": "0",
        "code": "LV",
        "en": "Latvia",
        "zn": "拉托维亚"
    },
    {
        "value": "0",
        "code": "LB",
        "en": "Lebanon",
        "zn": "黎巴嫩"
    },
    {
        "value": "0",
        "code": "LS",
        "en": "Lesotho",
        "zn": "莱索托"
    },
    {
        "value": "0",
        "code": "LR",
        "en": "Liberia",
        "zn": "利比里亚"
    },
    {
        "value": "0",
        "code": "LY",
        "en": "Libya",
        "zn": "利比亚"
    },
    {
        "value": "0",
        "code": "LI",
        "en": "Liechtenstein",
        "zn": "列支敦士登"
    },
    {
        "value": "0",
        "code": "LT",
        "en": "Lithuania",
        "zn": "立陶宛"
    },
    {
        "value": "0",
        "code": "LU",
        "en": "Luxembourg",
        "zn": "卢森堡"
    },
    {
        "value": "0",
        "code": "LC",
        "en": "St. Lucia",
        "zn": "圣卢西亚"
    },
    {
        "value": "0",
        "code": "MO",
        "en": "Macao",
        "zn": "中国澳门"
    },
    {
        "value": "0",
        "code": "MG",
        "en": "Malagasy",
        "zn": "马达加斯加"
    },
    {
        "value": "0",
        "code": "MW",
        "en": "Malawi",
        "zn": "马拉维"
    },
    {
        "value": "0",
        "code": "MY",
        "en": "Malaysia",
        "zn": "马来西亚"
    },
    {
        "value": "0",
        "code": "MV",
        "en": "Maldives",
        "zn": "马尔代夫"
    },
    {
        "value": "0",
        "code": "ML",
        "en": "Mali",
        "zn": "马里"
    },
    {
        "value": "0",
        "code": "MT",
        "en": "Malta",
        "zn": "马尔他"
    },
    {
        "value": "0",
        "code": "MH",
        "en": "Marshall Islands",
        "zn": "马绍尔群岛"
    },
    {
        "value": "0",
        "code": "MQ",
        "en": "Mauritania",
        "zn": "毛里塔尼亚　　　"
    },
    {
        "value": "0",
        "code": "MR",
        "en": "Mauritania",
        "zn": "毛里塔尼亚"
    },
    {
        "value": "0",
        "code": "MU",
        "en": "Mauritius",
        "zn": "毛里求斯"
    },
    {
        "value": "0",
        "code": "MX",
        "en": "Mexico",
        "zn": "墨西哥"
    },
    {
        "value": "0",
        "code": "MD",
        "en": "Moldova,Republic of",
        "zn": "摩尔多瓦"
    },
    {
        "value": "0",
        "code": "MC",
        "en": "Monaco",
        "zn": "摩纳哥"
    },
    {
        "value": "0",
        "code": "MN",
        "en": "Mongolia",
        "zn": "蒙古"
    },
    {
        "value": "0",
        "code": "MS",
        "en": "Montserrat",
        "zn": "蒙特塞拉特"
    },
    {
        "value": "0",
        "code": "MA",
        "en": "Morocco",
        "zn": "摩洛哥"
    },
    {
        "value": "0",
        "code": "MZ",
        "en": "Mozambique",
        "zn": "莫桑比克"
    },
    {
        "value": "0",
        "code": "MM",
        "en": "Burma",
        "zn": "缅甸"
    },
    {
        "value": "0",
        "code": "MP",
        "en": "Northern Nariana Islands",
        "zn": "纳里亚纳群岛北部　"
    },
    {
        "value": "0",
        "code": "NA",
        "en": "Namibia",
        "zn": "纳米比亚"
    },
    {
        "value": "0",
        "code": "NR",
        "en": "Naura",
        "zn": "瑙鲁"
    },
    {
        "value": "0",
        "code": "NP",
        "en": "Nepal",
        "zn": "尼泊尔"
    },
    {
        "value": "0",
        "code": "NL",
        "en": "Netherlands",
        "zn": "荷兰"
    },
    {
        "value": "0",
        "code": "NT",
        "en": "Neutral Zone",
        "zn": "中立区"
    },
    {
        "value": "0",
        "code": "NC",
        "en": "New Caledonia",
        "zn": "新喀里多尼亚"
    },
    {
        "value": "0",
        "code": "NZ",
        "en": "New Zealand",
        "zn": "新西兰"
    },
    {
        "value": "0",
        "code": "NI",
        "en": "Nicaragua",
        "zn": "尼加拉瓜"
    },
    {
        "value": "0",
        "code": "NE",
        "en": "Niger",
        "zn": "尼日尔"
    },
    {
        "value": "0",
        "code": "NG",
        "en": "Nigeria",
        "zn": "尼日利亚"
    },
    {
        "value": "0",
        "code": "NU",
        "en": "Niue",
        "zn": "纽埃"
    },
    {
        "value": "0",
        "code": "NF",
        "en": "Norfolk Island",
        "zn": "诺福克岛　"
    },
    {
        "value": "0",
        "code": "NO",
        "en": "Norway",
        "zn": "挪威"
    },
    {
        "value": "0",
        "code": "OM",
        "en": "Oman",
        "zn": "阿曼"
    },
    {
        "value": "0",
        "code": "PK",
        "en": "Pakistan",
        "zn": "巴基斯坦"
    },
    {
        "value": "0",
        "code": "PF",
        "en": "French Polynesia",
        "zn": "法属玻里尼西亚"
    },
    {
        "value": "0",
        "code": "PW",
        "en": "Palau",
        "zn": "帕劳"
    },
    {
        "value": "0",
        "code": "PA",
        "en": "Panama",
        "zn": "巴拿马"
    },
    {
        "value": "0",
        "code": "PG",
        "en": "Papua,Territory of",
        "zn": "巴布亚新几内亚"
    },
    {
        "value": "0",
        "code": "PY",
        "en": "Paraguay",
        "zn": "巴拉圭"
    },
    {
        "value": "0",
        "code": "PE",
        "en": "Peru",
        "zn": "秘鲁"
    },
    {
        "value": "0",
        "code": "PH",
        "en": "Philippines",
        "zn": "菲律宾"
    },
    {
        "value": "0",
        "code": "PN",
        "en": "Pitcairn Islands",
        "zn": "皮特开恩群岛"
    },
    {
        "value": "0",
        "code": "PL",
        "en": "Poland",
        "zn": "波兰"
    },
    {
        "value": "0",
        "code": "PT",
        "en": "Portugal",
        "zn": "葡萄牙"
    },
    {
        "value": "0",
        "code": "PR",
        "en": "Puerto Rico",
        "zn": "波多黎各（美）"
    },
    {
        "value": "0",
        "code": "QA",
        "en": "Qatar",
        "zn": "卡塔尔"
    },
    {
        "value": "0",
        "code": "RO",
        "en": "Romania",
        "zn": "罗马尼亚"
    },
    {
        "value": "0",
        "code": "RU",
        "en": "Russia",
        "zn": "俄罗斯联邦"
    },
    {
        "value": "0",
        "code": "RW",
        "en": "Rwanda",
        "zn": "卢旺达"
    },
    {
        "value": "0",
        "code": "SV",
        "en": "El Salvador",
        "zn": "萨尔瓦多"
    },
    {
        "value": "0",
        "code": "SH",
        "en": "St.Helena",
        "zn": "圣赫勒那"
    },
    {
        "value": "0",
        "code": "SM",
        "en": "San Marino",
        "zn": "圣马力诺"
    },
    {
        "value": "0",
        "code": "ST",
        "en": "Sao Tome and Principe",
        "zn": "圣多美与普林西比"
    },
    {
        "value": "0",
        "code": "SA",
        "en": "Saudi Arabia",
        "zn": "沙特阿拉伯"
    },
    {
        "value": "0",
        "code": "SN",
        "en": "Senegal",
        "zn": "塞内加尔"
    },
    {
        "value": "0",
        "code": "SC",
        "en": "Seychelles",
        "zn": "塞舌尔"
    },
    {
        "value": "0",
        "code": "SL",
        "en": "Sierra leone",
        "zn": "塞拉利昂"
    },
    {
        "value": "0",
        "code": "SG",
        "en": "Singapore",
        "zn": "新加坡"
    },
    {
        "value": "0",
        "code": "SK",
        "en": "Slovakia",
        "zn": "斯洛伐克"
    },
    {
        "value": "0",
        "code": "SI",
        "en": "Slovene",
        "zn": "斯洛文尼亚"
    },
    {
        "value": "0",
        "code": "SB",
        "en": "Solomon Islands",
        "zn": "所罗门群岛"
    },
    {
        "value": "0",
        "code": "SO",
        "en": "Somali",
        "zn": "索马里"
    },
    {
        "value": "0",
        "code": "SD",
        "en": "Sudan",
        "zn": "苏丹"
    },
    {
        "value": "0",
        "code": "SR",
        "en": "Surinam",
        "zn": "苏里南"
    },
    {
        "value": "0",
        "code": "SZ",
        "en": "Swaziland",
        "zn": "斯威士兰"
    },
    {
        "value": "0",
        "code": "SE",
        "en": "Sweden",
        "zn": "瑞典"
    },
    {
        "value": "0",
        "code": "SY",
        "en": "Syria",
        "zn": "叙利亚"
    },
    {
        "value": "0",
        "code": "SU",
        "en": "USSR(formerly)",
        "zn": "苏联(前)"
    },
    {
        "value": "0",
        "code": "TD",
        "en": "Chad",
        "zn": "乍得"
    },
    {
        "value": "0",
        "code": "TF",
        "en": "French Southern Territoties",
        "zn": "法属南方领土"
    },
    {
        "value": "0",
        "code": "TW",
        "en": "Taiwan",
        "zn": "中国台湾"
    },
    {
        "value": "0",
        "code": "TJ",
        "en": "Tsjikistan",
        "zn": "塔吉克斯坦"
    },
    {
        "value": "0",
        "code": "TZ",
        "en": "Tanzania",
        "zn": "坦桑尼亚"
    },
    {
        "value": "0",
        "code": "TH",
        "en": "Thailand",
        "zn": "泰国"
    },
    {
        "value": "0",
        "code": "TG",
        "en": "Togo",
        "zn": "多哥"
    },
    {
        "value": "0",
        "code": "TK",
        "en": "Tokela",
        "zn": "托克劳"
    },
    {
        "value": "0",
        "code": "TO",
        "en": "Tonga",
        "zn": "汤加"
    },
    {
        "value": "0",
        "code": "TT",
        "en": "Trinidad and Tobago",
        "zn": "特立尼达和多巴哥"
    },
    {
        "value": "0",
        "code": "TN",
        "en": "Tunisia",
        "zn": "突尼斯"
    },
    {
        "value": "0",
        "code": "TR",
        "en": "Turkey",
        "zn": "土尔其"
    },
    {
        "value": "0",
        "code": "TP",
        "en": "East Timor",
        "zn": "东帝"
    },
    {
        "value": "0",
        "code": "TM",
        "en": "Turkomanstan",
        "zn": "土库曼斯坦"
    },
    {
        "value": "0",
        "code": "TC",
        "en": "Turks and Caicos Islands",
        "zn": "特克斯和凯科斯群岛"
    },
    {
        "value": "0",
        "code": "TV",
        "en": "Tuvalu",
        "zn": "图瓦卢　　　　"
    },
    {
        "value": "0",
        "code": "UG",
        "en": "Uganda",
        "zn": "乌干达"
    },
    {
        "value": "0",
        "code": "UA",
        "en": "Ukiain",
        "zn": "乌克兰"
    },
    {
        "value": "0",
        "code": "UK",
        "en": "England",
        "zn": "英国（正式代码为GB）"
    },
    {
        "value": "0",
        "code": "US",
        "en": "America",
        "zn": "美国"
    },
    {
        "value": "0",
        "code": "UY",
        "en": "uruguay",
        "zn": "乌拉圭"
    },
    {
        "value": "0",
        "code": "UZ",
        "en": "Uzbekstan",
        "zn": "乌兹别克斯坦"
    },
    {
        "value": "0",
        "code": "VA",
        "en": "Vatican",
        "zn": "梵蒂冈（罗马教庭）"
    },
    {
        "value": "0",
        "code": "VE",
        "en": "Venezuela",
        "zn": "委内瑞拉"
    },
    {
        "value": "0",
        "code": "VN",
        "en": "Vietnam",
        "zn": "越南"
    },
    {
        "value": "0",
        "code": "VG",
        "en": "Virgin Islands(British)",
        "zn": "不列颠岛(英)"
    },
    {
        "value": "0",
        "code": "VI",
        "en": "Vigin Islands(U.S.)",
        "zn": "不列颠岛(美)"
    },
    {
        "value": "0",
        "code": "WS",
        "en": "Western Samoa",
        "zn": "西萨摩亚"
    },
    {
        "value": "0",
        "code": "YE",
        "en": "Yemen",
        "zn": "也门"
    },
    {
        "value": "0",
        "code": "YU",
        "en": "Yugoslavia",
        "zn": "南斯拉夫"
    },
    {
        "value": "0",
        "code": "ZA",
        "en": "South Africa",
        "zn": "南非"
    },
    {
        "value": "0",
        "code": "ZR",
        "en": "Zaire",
        "zn": "扎伊尔"
    },
    {
        "value": "0",
        "code": "ZM",
        "en": "Zambia",
        "zn": "赞比亚"
    },
    {
        "value": "0",
        "code": "ZW",
        "en": "Zimbabwe",
        "zn": "津巴布韦"
    }
]

<template>
  <div class="top5-wrap">
    <h3>{{$t('adminHomePage.devOS')}} (top5)</h3>

    <div v-if="!chartData.length" class="no-data">{{$t('noData')}}</div>

    <div v-else style="display:flex;margin-top:30px">
      <div class="wrap">
        <div class="wrap-ech">
          <v-chart :options="opt" autoresize />
        </div>
      </div>
      <div class="list">
        <div class="item" v-for="(item, index) in this.opt.series.data" :key="item.name + index">
          <img v-if="+index < 4" :src="index === 0 ? pngTop1 : index === 1 ? pngTop2 : pngTop3" />
          <span>{{ item.name || '-' }}</span>
          <span>{{ item.percent || '-' }}</span>
          <span>{{ item.value || '-' }}{{$t('adminHomePage.tower')}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'
import pngTop1 from '@/assets/img/top1.png'
import pngTop2 from '@/assets/img/top2.png'
import pngTop3 from '@/assets/img/top3.png'

export default {
  props: ['chartData'],
  components: {
    VChart: VChart
  },

  data() {
    return {
      pngTop1,
      pngTop2,
      pngTop3,
      top: 5,
      opt: {
        color: ['#42a5f3', '#fcca2c', '#ccccdd', '#6d7c87', '#48c46b', '#fe6f44'],
        tooltip: {
          trigger: 'axis',
          formatter: '{b} : {c}'+this.$t('adminHomePage.tower'),
          confine: true
        },
        grid: {
          left: '15%'
        },
        yAxis: {
          name: this.$t('adminHomePage.tower'),
          nameGap: 24,
          minInterval: 1,
          type: 'category',
          inverse: true,
          data: [],
          axisLine: {
            lineStyle: {
              color: '#9a9a9a'
            }
          },
          axisTick: {
            lineStyle: {
              color: '#9a9a9a'
            }
          },
          axisLabel: {
            color: '#000'
          }
        },
        xAxis: {
          type: 'value',
          minInterval: 1,
          axisLine: {
            lineStyle: {
              color: '#9a9a9a'
            }
          },
          axisTick: {
            lineStyle: {
              color: '#9a9a9a'
            }
          },
          axisLabel: {
            color: '#000'
          }
        },
        series: {
          type: 'bar',
          itemStyle: {
            color: '#ee6b43'
          },
          data: [],
          barCategoryGap: '80%'
        }
      }
    }
  },

  methods: {
    renderData(res) {
      const opt = { ...this.opt }
      opt.series.data = []
      opt.yAxis.data = []

      const total = res.reduce((num, item) => {
        return (num += item.total)
      }, 0)

      if (res && res.length) {
        opt.series.data = res.map((_, index) => {
          return {
            itemStyle: {
              color: opt.color[index % opt.color.length]
            },
            value: _.total,
            name: _.name,
            percent: ((_.total / total) * 100).toFixed(2) + '%'
          }
        })
        let first = this.$t('adminHomePage.first'),
        second = this.$t('adminHomePage.second'),
        third = this.$t('adminHomePage.third'),
        fourth = this.$t('adminHomePage.fourth'),
        fifth = this.$t('adminHomePage.fifth'),
        arrs = [first,second,third,fourth,fifth]
    
        const targetArr = JSON.parse(JSON.stringify(arrs))

        opt.yAxis.data = targetArr.slice(0, res.length)
      }

      this.opt = opt
    }
  },
  watch: {
    chartData(val) {
      if (val && val.length) {
        const res = val.map(item => ({
          name: 'Android ' + item.systemVersion,
          total: item.deviceNumber
        }))
        this.renderData(res)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.top5-wrap {
  position: relative;
  height: 100%;
}
h3 {
  font-weight: bold;
  color: #303133;
  font-size: 16px;
  padding: 10px 20px;
}
.wrap {
  flex: 1;
  position: relative;
  height: 300px;
  border-radius: 6px;
  .wrap-ech {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    .echarts {
      flex: 1;
      width: 100%;
    }
  }
}
.list {
  flex: 1;
  margin-top: 32px;

  .item {
    font-size: 14px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    letter-spacing: 0px;
    height: 50px;
    color: #000000;
    position: relative;
    justify-content: space-around;
    color: #9898a5;

    span {
      max-width: 100px;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .img {
      width: 30px;
      height: 37px;
    }
  }
}

::v-deep .el-divider {
  margin: 0 !important;
  position: relative;
  top: 24px;
}
</style>

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import InjectPlugin from '@/lib/inject'
import App from './views/layouts/App'
import routerController from './router/controller'
import store from './store'
import i18n from '@/i18n/index'

Vue.config.productionTip = false
Vue.use(InjectPlugin)
;(async () => {
  const router = await routerController()
  new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})()

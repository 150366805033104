<template>
  <el-container class="container-wrap">
    <slot name="slideMenu"></slot>
    <div class="right-wrap">
      <slot name="header"></slot>
      <el-container class="container">
        <slot v-if="$route.name === 'home'"></slot>
        <el-main v-else>
          <template v-if="headerTabs && headerTabs.length && !$route.meta.hideHeaderTab">
            <el-tabs
              ref="tabs"
              size="small"
              v-model="currentTab"
              type="card"
              closable
              @tab-click="toggleRouter"
              @tab-remove="tabRemove"
            >
              <el-tab-pane :key="item.name" v-for="item in headerTabs" :name="item.path">
                <span
                  slot="label"
                  :title="item.title"
                  :name="item.name"
                  @dblclick="tabRemove(item.name)"
                  @contextmenu.prevent="contextmenu($event, item.path)"
                >
                  {{ item.abbreviation }}
                </span>
              </el-tab-pane>
            </el-tabs>
          </template>

          <div class="main-cot">
            <h4 v-if="currentTitle">{{ $t('basicsData.' + currentTitle) }}</h4>
            <slot></slot>
          </div>
        </el-main>
      </el-container>
      <ul v-show="visible" :style="menuStyle" class="contextmenu">
        <li @click="closeOtherTab">{{ $t('closeOther') }}</li>
        <li @click="closeRightTab">{{ $t('closeRightOther') }}</li>
      </ul>
    </div>
  </el-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      currentPath: ''
    }
  },
  async created() {},
  computed: {
    link() {
      return this.$store.state.headerLink.find(item => item.name === this.currentPage)
    },
    ...mapState({
      headerTabs: state => {
        return (state.headerTabs || []).map(item => {
          item.abbreviation =
            item.title && item.title.length > 8 ? `${item.title.substring(0, 8)}...` : item.title
          return item
        })
      }
    }),

    currentPage() {
      return this.$store.state.currentPage
    },
    currentTitle() {
      return this.$store.state.currentTitle
    },
    currentTab: {
      get() {
        return this.$route.path
      },
      set(val) {
        this.$store.commit(`SET_CURRENT_TAB`, val)
      }
    },
    menuStyle() {
      return {
        left: `${this.left}px`,
        top: `${this.top}px`
      }
    }
  },
  methods: {
    async tabRemove(path) {
      console.log('path', path)
      const sname = await this.$store.dispatch(`delCurrentTab`, path)
      if (sname) {
        this.$router.push({ name: sname })
      }
    },
    contextmenu(e, path) {
      const el = e.target.parentNode
      const rect = el.getBoundingClientRect()
      this.left = rect.x + el.offsetWidth - 50
      this.top = rect.y + el.offsetHeight / 2
      this.visible = true
      this.currentPath = path
      console.log(22, this.currentTab, this.headerTabs, path)
    },
    closeMenu() {
      this.visible = false
    },
    closeOtherTab() {
      if (!this.currentPath) return

      const index = this.headerTabs.findIndex(item => item.path === this.currentPath)

      if (index === -1) return

      const headerTabs = [...this.headerTabs].splice(index, 1)

      if (!headerTabs.length) return

      this.$store.commit(`SET_HEADER_TABS`, headerTabs)
      this.$store.commit(`SET_CURRENT_TAB`, headerTabs[0].path)
    },
    closeRightTab() {
      if (!this.currentPath) return

      const index = this.headerTabs.findIndex(item => item.path === this.currentPath)

      if (index === -1) return

      const headerTabs = [...this.headerTabs]
      headerTabs.length = index + 1

      this.$store.commit(`SET_HEADER_TABS`, headerTabs)
      this.$store.commit(`SET_CURRENT_TAB`, this.currentPath)
    },
    toggleRouter(e) {
      console.log('e', e, e.name)
      const tab = this.headerTabs.find(item => item.path === e.name)
      if (tab) {
        this.$router.replace({
          name: tab.name,
          query: tab.query || {},
          params: tab.params || {}
        })
      }
    }
  },
  watch: {
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-main {
  padding: 0 10px 10px;
}
.container-wrap {
  height: 100%;
  display: flex;

  .right-wrap {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex: 1;
    background: #f6f6f6;
    ::v-deep .el-header {
      padding: 0 40px;
    }
  }
}
.container {
  display: flex;
  overflow-y: auto;

  ::v-deep .el-main {
    display: flex;
  }

  .main-cot {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #ffffff;
    border-radius: 4px;
    border: solid 1px #dcdcdc;
    padding: 10px 10px 0;
    min-width: 990px;
    overflow: hidden;
  }
}
::v-deep .el-tabs__header {
  margin-bottom: 0;
  .el-tabs__item.is-active {
    background: #fff;
  }
}
.el-main {
  flex-direction: column;
}
.contextmenu {
  height: auto;
  width: auto;
  position: absolute;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
  padding: 6px 0;
  z-index: 222;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  li {
    display: block;
    line-height: 24px;
    height: 24px;
    width: 118px;
    font-size: 12px;
    cursor: default;
    text-indent: 14px;
    color: #666;
  }

  li:hover {
    background-color: #f5f5f5;
  }
}
</style>

<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    :close-on-click-modal="false"
    width="900px"
    :title="$t('spaceDetails.spaceDetails')"
    class="spaceModal"
    :visible.sync="show"
  >
    <div class="content">
      <div class="leftP">
        <div class="up">
          <resource-pie :chartData="pieData" />
        </div>
        <div class="down">
          <div class="row">
            <span class="label">{{ $t('spaceDetails.total') }}</span>
            <span class="value">{{ all }}</span>
          </div>
          <div class="row">
            <span class="label">{{ $t('spaceDetails.video') }}</span>
            <span class="value">{{ video }}</span>
          </div>
          <div class="row">
            <span class="label">{{ $t('spaceDetails.photo') }}</span>
            <span class="value">{{ photo }}</span>
          </div>
          <div class="row">
            <span class="label">{{ $t('spaceDetails.installBag') }}</span>
            <span class="value">{{ installBag }}</span>
          </div>
        </div>
      </div>
      <div class="rightP">
        <el-select style="margin-bottom:10px" v-model="selectType" clearable @change="selectChange">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>

        <el-table :data="tableData" border height="400">
          <el-table-column
            show-overflow-tooltip
            prop="name"
            :label="$t('spaceDetails.resourceName')"
          />
          <el-table-column
            show-overflow-tooltip
            prop="size"
            :label="$t('spaceDetails.resourceSize')"
          />
          <el-table-column
            show-overflow-tooltip
            prop="type"
            :label="$t('spaceDetails.resourceType')"
          />
          <el-table-column :label="$t('operation')">
            <template slot-scope="{ row }">
              <el-button type="text" @click="delHandler(row)">{{ $t('delete') }}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          slot="pagination"
          :total="total"
          :page-size.sync="params.pageSize"
          :page.sync="params.currentPage"
          @change="getData"
        />
      </div>
    </div>
  </el-dialog>
</template>
<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import resourcePie from './resourcePie'
export default {
  mixins: [dialogCommonParams],
  props: ['username'],
  components: {
    resourcePie
  },
  data() {
    return {
      params: {
        pageSize: 20,
        currentPage: 1
      },
      all: 0,
      video: 0,
      photo: 0,
      installBag: 0,
      total: 0,
      pieData: [],
      tableData: [],
      selectType: 1,
      options: [
        {
          label: this.$t('spaceDetails.video'),
          value: 1
        },
        {
          label: this.$t('spaceDetails.photo'),
          value: 2
        },
        {
          label: this.$t('spaceDetails.installBag'),
          value: 3
        }
      ]
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        //  const res =  await this.$api.systemManagement.spaceList()
      } catch (e) {
        console.log(e)
      }
    },
    delHandler(row) {},
    selectChange() {},
    handleSizeChange() {
      this.getData()
    },
    handleCurrentChange() {
      this.getData()
    }
  }
}
</script>
<style scoped lang="scss">
.spaceModal {
  .content {
    display: flex;

    .leftP {
      display: flex;
      flex-direction: column;
      width: 300px;
      margin-right: 40px;
      .up {
        height: 300px;
        margin-bottom: 16px;
      }

      .down {
        display: flex;
        flex-direction: column;

        .row {
          display: flex;
          justify-content: space-around;
          .label {
            width: 100px;
            text-align: left;
          }
        }
      }
    }

    .rightP {
      flex: 1;
    }
  }
}
</style>

<template>
  <div class="wrap">
    <h3>{{ $t('customerHome.devicesTotal') }}</h3>
    <div class="wrap-ech">
      <v-chart :options="opt" autoresize />
      <div class="totalDevice">
        <span class="label">{{ $t('customerHome.totalDevicesNumber') }}</span>
        <span class="value">{{
          (deviceNumberStatisticResVo.increaseTotal || 0).toLocaleString()
        }}</span>
      </div>
      <!-- <div class="countWrap">
        <span class="onlineCount">{{
          (deviceNumberStatisticResVo.onlineNumber || 0).toLocaleString()
        }}</span>
        <span>{{ (deviceNumberStatisticResVo.offlineNumber || 0).toLocaleString() }}</span>
      </div> -->
      <!-- <div v-if="!chartData.length" class="no-data">暂无数据</div> -->
    </div>
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'

export default {
  props: ['deviceNumberStatisticResVo'],
  components: {
    VChart: VChart
  },
  data() {
    return {
      opt: {
        color: ['#074d29', '#bf8962'],
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} ({d}%)',
          confine: true
        },
        legend: {
          // orient: 'vertical',
          data: [
            // this.$t('customerHome.onlineEquipment') +
            //   ':' +
            //   (this.deviceNumberStatisticResVo.onlineNumber || 0),
            // this.$t('customerHome.offlineEquipment') +
            //   ':' +
            //   (this.deviceNumberStatisticResVo.offlineNumber || 0)
          ],
          icon: 'circle',
          itemWidth: 8,
          itemGap: 20,
          top: '85%',
          left: 'center',
          textStyle: {
            color: '#333'
          }
        },
        series: {
          name: this.$t('adminHomePage.devReception'),
          type: 'pie',
          radius: ['34%', '46%'],
          center: ['30%', '52%'],
          data: [
            {
              name: '',
              value: '0'
            },
            {
              name: '',
              value: '0'
            }
          ],
          label: {
            show: false
          },
          labelLine: {
            show: false
          },
          emphasis: {
            label: {
              show: true,
              formatter: '{d}%'
            },
            labelLine: {
              show: true
            }
          }
        }
      }
    }
  },
  created() {},
  watch: {
    deviceNumberStatisticResVo: {
      handler(val) {
        let legend1 =
            this.$t('customerHome.onlineEquipment') +
            ':' +
            (this.deviceNumberStatisticResVo.onlineNumber || 0),
          legend2 =
            this.$t('customerHome.offlineEquipment') +
            ':' +
            (this.deviceNumberStatisticResVo.offlineNumber || 0)
        this.opt.legend.data = [legend1, legend2]

        this.opt.series.data[0].name = legend1
        this.opt.series.data[0].value = this.deviceNumberStatisticResVo.onlineNumber || 0
        this.opt.series.data[1].name = legend2
        this.opt.series.data[1].value = this.deviceNumberStatisticResVo.offlineNumber || 0
      }
    },
    deep: true
  }
}
</script>

<style scoped lang="scss">
.wrap {
  position: relative;
  height: 100%;

  h3 {
    font-weight: normal;
    color: #333;
    font-size: 16px;
    padding: 30px 20px;
  }

  .wrap-ech {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    .echarts {
      flex: 1;
      width: 100%;
    }

    .totalDevice {
      position: absolute;
      top: 20%;
      right: 5%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .label {
        font-size: 16px;
        margin-bottom: 4px;
        color: #333;
      }

      .value {
        font-size: 35px;
        color: #333;
      }
    }
    .countWrap {
      position: absolute;
      top: 60%;
      right: 6%;
      font-size: 13px;
      color: #333;
      display: flex;
      flex-direction: column;
      .onlineCount {
        margin-bottom: 16px;
      }
    }
  }
}
</style>

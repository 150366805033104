import axios from '../lib/request.js'
import { PLATFORM } from '../lib/constant'

const api = {
  /* 应用 */

  //应用列表
  appList: data => axios.post('/app/list', data),

  //所有应用
  allApp: data => axios.post('/app/getList', data),

  //客户列表
  spList: params => axios.get('/sp/listSpInfo', { params }),

  //上传应用
  appAdd: data =>
    axios.post('/apk/upload', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }),

  //删除应用
  appDelete: params => axios.delete('/app/delete', { params }),

  //删除应用版本
  appDeleteVersion: params => axios.delete('/app/deleteByAppId', { params }),

  // 应用更新
  appUpdate: data =>
    axios.post('/apk/upgrade', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }),

  //下拉版本列表
  getAppsByPackageName: params => axios.get('/app/getAppsByPackageName', { params }),

  //管理平台下拉版本列表
  getAppsByPackageNameNew: params => axios.get('/app/getAppsByPackageNameAndModel', { params }),

  //管理平台下拉版本列表
  getAppsByPackageNameAndSign: params => axios.get('/app/getAppsByPackageNameAndSign', { params }),

  //获取APP链接
  getAppUrl: data =>
    axios.post('/apk/download', data, { headers: { 'Content-Type': 'multipart/form-data' } }),

  //应用详情
  appGet: params => axios.get('/app/get', { params }),

  /* 发布管理 */

  //删除发布信息
  publishDel: params => axios.delete('/publish/delete', { params }),

  //发布详情
  publishDetail: params => axios.get('/publish/get', { params }),

  //发布列表
  publishList: data => axios.post('/publish/list', data),

  //时间发布列表
  publishListAll: data => axios.post('/publish/listAll', data),

  //发布应用
  publishPost: data => axios.post('/publish/publish', data),

  //卸载应用
  unloadPost: data => axios.post('/publish/uninstall', data),

  //取消自启动应用
  cancelAutoStart: data => axios.post('/publish/cancelAutoStart', data),

  //根据发布ID查询应用列表-按设备查看
  listFromDevice: data => axios.post('/publish/listDeviceByPublishId', data),

  //根据应用ID查询设备列表-按应用查看
  listFromApp: data => axios.post('/publish/listDeviceByAppId', data),

  //根据发布ID查询应用列表
  checkAppList: data => axios.post('/publish/listAppByPublishId', data),

  //根据发布ID导出设备列表
  downloadList: params =>
    axios.get('/publish/exportDeviceListByPublishId', { params, responseType: 'blob' }),

  //根据发布ID导出设备列表
  downloadListByStatus: params =>
    axios.get('/publish/exportDeviceListByPublishStatus', { params, responseType: 'blob' })
}

export default api

import routerControll from './index'
import store from '../store/index'
import i18n from '@/i18n/index'

export default async () => {
  const router = await routerControll()

  router.beforeEach(async (to, from, next) => {
    // 每个页面按钮权限配置

    if (to.name !== 'login' && !store.state.headerLink.length) {
      await store.dispatch('renderMenu')
    }

    const headerLink = store.state.headerLink

    if (headerLink.length) {
      try {
        const parentName = headerLink.find(_ => _.name === to.matched[0].name)

        if (parentName) {
          const child = parentName.list.find(_ => _.name === to.name)
          store.dispatch('setCurrentTitle', (child && child.title) || '')

          if (child) {
            to.meta.auth = child.list.reduce((targetObj, item) => {
              targetObj[item.name] = true
              return targetObj
            }, {})
          }
        }
      } catch (e) {
        console.log(e)
      }
    }

    if (to.matched.length && to.matched[0].meta.type) {
      // 点击顶部导航栏切换路由
      store.dispatch('setCurrentPage', to.matched[0].meta.type)
    }
    let title = i18n.messages[i18n.locale].basicsData[store.state.currentTitle]
    console.log('to', to, title)
    if (!to.meta.hideHeaderTab) {
      if (title) {
        store.dispatch(`setHeaderTabs`, {
          path: to.path,
          title,
          name: to.query.iframeName || to.name,
          query: { ...to.query },
          params: { ...to.params }
        })
      }
    }

    next()
  })

  router.afterEach(to => {
    // document.title = (to.meta && to.meta.title) || ''
  })

  return router
}

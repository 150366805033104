export const HOST = process.env.NODE_ENV === 'development' ? 'scp.dev.sedsy.com' : location.host
// export const HOST = process.env.NODE_ENV === 'development' ? 'fw.sedsy.com' : location.host

export const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? `http://${HOST}/scpserver`
    : location.protocol + '//' + location.host + '/scpserver'

export const PLATFORM = location.pathname.includes('admin') ? 'admin' : 'service'

// 是否是测试环境或开发环境
export const isDev =
  process.env.NODE_ENV === 'development' || location.href.includes('scp.dev.sedsy.com')

import systemManagement from '@/api/systemManagement'

const actions = {
  setCurrentPage({ commit }, name) {
    commit('SET_CURRENT_PAGE', name)
  },
  setCurrentTitle({ commit }, name) {
    commit('SET_CURRENT_TITLE', name)
  },
  async renderMenu({ commit }) {
    try {
      const res = await systemManagement.resourceUser()

      if (res.result && res.result.length) {
        const resource = res.result
          .filter(_ => _.resourceName && _.resourceDesc)
          .sort((a, b) => a.orderNum - b.orderNum)

        const createTree = (resource, id = null) => {
          return resource
            .filter(item => !item.status)
            .filter(item => item.parentId === id)
            .map(item => ({
              icon: item.icon || 'icondaohang_shouye',
              name: item.resourceName,
              title: item.resourceDesc,
              list: createTree(resource, item.resourceId)
            }))
        }
        commit('SET_MENU', createTree(resource))
      }
    } catch (e) {
      console.log(e)
    }
  },
  delCurrentTab({ state, commit }, path) {
    if (!path) return

    const index = state.headerTabs.findIndex(item => item.path === path)
    let sname = ``

    if (index !== -1) {
      const tabs = [...state.headerTabs]
      tabs.splice(index, 1)

      if (path === state.currentTab && tabs.length) {
        let curTab = tabs[index - 1] ? tabs[index - 1] : tabs[0]
        sname = curTab.name
        commit('SET_CURRENT_TAB', curTab.path)
      }

      commit('SET_HEADER_TABS', tabs)
    }
    return sname
  },
  setHeaderTabs({ state, commit }, params) {
    const index = state.headerTabs.findIndex(item =>
      params.query.iframeName
        ? params.query.iframeName === item.query.iframeName
        : item.path === params.path
    )

    const tabs = [...state.headerTabs]

    if (index === -1) {
      tabs.push({ ...params, key: Date.now() })
    } else {
      tabs.splice(index, 1, { ...params, key: state.headerTabs[index].key })
    }

    commit('SET_HEADER_TABS', tabs)
    commit('SET_CURRENT_TAB', params.path)
  }
}

export default actions

<template>
  <div class="wrap">
    <h3>{{$t('adminHomePage.serviceProvider')}} (top5)</h3>
    <div class="wrap-ech">
      <v-chart :options="opt" autoresize />
      <div v-if="!chartData.length" class="no-data">{{$t('noData')}}</div>
    </div>
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'

export default {
  props: ['chartData'],
  components: {
    VChart: VChart
  },

  data() {
    return {
      opt: {
        color: ['#42a5f3', '#fcca2c', '#ccccdd', '#6d7c87', '#48c46b', '#fe6f44'],
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c}'+this.$t('adminHomePage.tower')+' ({d}%)',
          confine: true
        },
        legend: {
          bottom: 20,
          data: []
        },
        series: {
          name: this.$t('adminHomePage.devGroup'),
          type: 'pie',
          radius: '45%',
          center: ['50%', '50%'],
          data: []
        }
      }
    }
  },
  watch: {
    chartData(val) {
      if (val && val.length) {
        const legend = []
        const data = []
        const targetData = [...val]
        targetData.forEach(item => {
          legend.push(item.spName || '-')
          data.push({
            name: item.spName || '-',
            value: item.deviceNumber
          })
        })

        this.opt.legend.data = legend
        this.opt.series.data = data
      }
    }
  }
}
</script>

<style scoped lang="scss">
.wrap {
  position: relative;
  height: 100%;
  h3 {
    font-weight: bold;
    color: #303133;
    font-size: 16px;
    padding: 10px 20px;
  }
  .wrap-ech {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    .echarts {
      flex: 1;
      width: 100%;
    }
  }
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import AdminHome from '@/pages/admin/modules/home'
import ServiceHome from '@/pages/index/modules/home'
import Login from '@/views/login/'
import { PLATFORM } from '@/lib/constant.js'

const [routerPush, routerReplace] = [VueRouter.prototype.push, VueRouter.prototype.replace]
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}
VueRouter.prototype.replace = function replace(location) {
  return routerReplace.call(this, location).catch(error => error)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    meta: {
      type: 'home',
      hideHeaderTab: true //隐藏顶部tabs 面包屑
    },
    component: PLATFORM === 'service' ? ServiceHome : AdminHome
  },
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/remoteSd',
    name: 'remoteSd',
    component: () => import('@/views/remoteSd/index')
  }
]

export default async () => {
  let needModules = [
    'appManagement',
    'deviceManagement',
    'systemManagement',
    'statisticalManagement',
    'marketManagement',
    'otaManagement'
  ]
  let type = 'admin'

  if (PLATFORM === 'service') {
    type = 'index'
    needModules.length = 5
  }

  let moduleRoutes = []

  for (let i = 0; i < needModules.length; i++) {
    const moduleName = needModules[i]
    const routes = await import(`@/pages/${type}/modules/${moduleName}/router`)
    moduleRoutes.push(...routes.default)
  }

  return new VueRouter({
    routes: [...routes, ...moduleRoutes]
  })
}

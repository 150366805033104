<template>
  <div class="home">
    <div class="header">
      <div class="item">
        <span class="t">{{ $t('adminHomePage.deviceTotal') }}</span>
        <span class="num">{{ deviceNumberStatisticResVo.increaseTotal }}</span>
        <div>
          <span class="small"
            >{{ $t('adminHomePage.today') }} +{{ deviceNumberStatisticResVo.increaseToday }}</span
          >
          <span class="small" style="margin-left:25px"
            >{{ $t('adminHomePage.month') }} +{{ deviceNumberStatisticResVo.increaseMonth }}</span
          >
        </div>
      </div>
      <div class="item">
        <span class="t">{{ $t('adminHomePage.pos') }}</span>
        <span class="num">{{ deviceSeriesStatisticResVoList.pos }}</span>
      </div>
      <div class="item">
        <span class="t"> {{ $t('adminHomePage.selfHelp') }}</span>
        <span class="num">{{ deviceSeriesStatisticResVoList.self }}</span>
      </div>
      <div class="item">
        <span class="t">{{ $t('adminHomePage.commercialDisplay') }}</span>
        <span class="num">{{ deviceSeriesStatisticResVoList.mch }}</span>
      </div>
    </div>

    <div class="row">
      <div class="item">
        <device-type :chartData="deviceModelStatisticResVoList" />
      </div>
      <div class="item">
        <device-service :chartData="deviceSpStatisticResVoList" />
      </div>
    </div>

    <div class="row">
      <div class="item">
        <device-firmware :chartData="deviceFirmwareStatisticResVoList" />
      </div>

      <div class="item">
        <device-area :chartData="deviceRegionStatisticResVoList" />
      </div>
    </div>

    <div class="row">
      <div class="item">
        <device-os :chartData="systemVersionStatisticResVoList" />
      </div>
      <div class="item">
        <device-app :chartData="deviceAppStatisticResVoList" />
      </div>
    </div>
  </div>
</template>

<script>
import DeviceType from './components/DeviceType'
import DeviceService from './components/DeviceService'
import DeviceArea from './components/DeviceArea'
import DeviceOs from './components/DeviceOs'
import DeviceApp from './components/DeviceApp'
import DeviceFirmware from './components/DeviceFirmware'

export default {
  data() {
    return {
      deviceNumberStatisticResVo: {
        increaseWeek: 0,
        increaseMonth: 0,
        increaseTotal: 0,
        comparedLastWeek: 0,
        comparedLastMonth: 0
      },
      deviceSeriesStatisticResVoList: {
        pos: 0,
        self: 0,
        mch: 0
      },
      deviceModelStatisticResVoList: [],
      deviceRegionStatisticResVoList: [],
      deviceAppStatisticResVoList: [],
      systemVersionStatisticResVoList: [],
      deviceSpStatisticResVoList: [],
      deviceFirmwareStatisticResVoList: []
    }
  },

  created() {},
  components: {
    DeviceType,
    DeviceService,
    DeviceArea,
    DeviceOs,
    DeviceApp,
    DeviceFirmware
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        this.loading = true
        const res = await this.$api.common.getCountSummary()
        if (res.result) {
          this.deviceNumberStatisticResVo = res.result.deviceNumberStatisticResVo
          this.deviceModelStatisticResVoList = res.result.deviceModelStatisticResVoList || []
          this.deviceRegionStatisticResVoList =
            res.result.deviceRegionStatisticResVoList
              .sort((a, b) => b.deviceNumber - a.deviceNumber)
              .slice(0, 10) || []
          this.deviceAppStatisticResVoList =
            res.result.deviceAppStatisticResVoList
              .sort((a, b) => b.deviceNumber - a.deviceNumber)
              .slice(0, 5) || []
          this.systemVersionStatisticResVoList =
            res.result.systemVersionStatisticResVoList
              .sort((a, b) => b.deviceNumber - a.deviceNumber)
              .slice(0, 5) || []
          this.deviceSpStatisticResVoList =
            res.result.deviceSpStatisticResVoList
              .sort((a, b) => b.deviceNumber - a.deviceNumber)
              .slice(0, 5) || []
          this.deviceFirmwareStatisticResVoList =
            res.result.deviceFirmwareStatisticResVoList
              .sort((a, b) => b.deviceNumber - a.deviceNumber)
              .slice(0, 5) || []

          if (res.result.deviceSeriesStatisticResVoList) {
            res.result.deviceSeriesStatisticResVoList.forEach(item => {
              if (item.series === 1) {
                this.deviceSeriesStatisticResVoList.pos = item.deviceNumber
              } else if (item.series === 2) {
                this.deviceSeriesStatisticResVoList.self = item.deviceNumber
              } else if (item.series === 3) {
                this.deviceSeriesStatisticResVoList.mch = item.deviceNumber
              }
            })
          }
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  width: 100%;

  ::v-deep .el-divider {
    margin: 24px 0;
  }
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .item {
      display: flex;
      flex-direction: column;
      padding: 10px 0 20px 32px;
      height: 120px;
      border-radius: 4px;
      flex: 1;
      box-sizing: border-box;
      color: #000;
      background: #fff;

      & + .item {
        margin-left: 20px;
      }

      .t {
        font-size: 20px;
        font-size: bold;
        color: #fff;
      }
      .num {
        font-size: 30px;
        line-height: 50px;
        font-weight: bold;
        color: #fff;
      }
      .small {
        font-size: 14px;
        font-weight: normal;
        color: #fff;
        i {
          font-size: 26px;
          position: relative;
          top: 3px;
        }
      }

      // &:nth-child(1) {
      //   background: #8f83ff;
      // }
      // &:nth-child(2) {
      //   background: #074d29;
      // }
      // &:nth-child(3) {
      //   background: #ffbb17;
      // }
      // &:nth-child(4) {
      //   background: #de5dd0;
      // }

      &:nth-child(1) {
        background: linear-gradient(-90deg, #6d7c87 0%, #6d7c87 100%),
          linear-gradient(#6d7c87, #6d7c87);
      }
      &:nth-child(2) {
        background: linear-gradient(-90deg, #48c46b 0%, #68db88 100%),
          linear-gradient(#48c46b, #48c46b);
      }
      &:nth-child(3) {
        background: linear-gradient(-90deg, #42a5f3 0%, #83beec 100%),
          linear-gradient(#42a5f3, #42a5f3);
      }
      &:nth-child(4) {
        background: linear-gradient(-90deg, #fcca2c 0%, #fbba3c 100%),
          linear-gradient(#fcca2c, #fcca2c);
      }
    }
  }
  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .row {
    display: flex;
    margin-bottom: 20px;

    .item {
      flex: 1;
      height: 400px;
      background: #fff;
      border-radius: 4px;
      border: solid 1px #dcdcdc;

      & + .item {
        margin-left: 20px;
      }
    }
  }
  .tabs-wrap {
    position: relative;
    .title {
      position: absolute;
    }
    .tabs {
      display: flex;
      border-bottom: 1px solid #cccccc;
      justify-content: flex-end;
      span {
        font-size: 14px;
        color: #000;
        padding: 0 8px 20px;
        cursor: pointer;
        &.active,
        &:hover {
          color: #c7000a;
          position: relative;
          &::after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 2px;
            z-index: 22;
            background: #c7000a;
          }
        }
        & + span {
          margin-left: 45px;
        }
      }
    }
  }

  ::v-deep .el-divider__text {
    font-weight: bold;
  }
}
</style>

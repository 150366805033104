<template>
  <el-aside
    class="asibe"
    :class="{ 'en-asibe': $i18n.locale != 'zh' }"
    :style="{ width: $i18n.locale == 'zh' ? '201px' : '280px' }"
  >
    <el-menu
      class="menu"
      @open="handleOpen"
      @close="handleClose"
      background-color="#fff"
      text-color="#333"
      active-text-color="#074d29"
      :unique-opened="true"
      :default-openeds="openKeys"
      :default-active="$route.name"
    >
      <div class="imgWrap">
        <img src="../../assets/img/logo.png" class="logo" alt="logo" />
      </div>
      <template v-for="menu in headerLink">
        <el-menu-item
          class="home-item"
          @click="routerClick(menu.name, menu.title)"
          :index="menu.name"
          :key="menu.name"
          v-if="!menu.list || !menu.list.length"
        >
          <icon-svg v-if="menu.icon" :icon-class="menu.icon" />

          <router-link :to="{ name: menu.name }">
            <!--  menu.title  -->
            {{ getTitle(menu) }}
          </router-link>
        </el-menu-item>

        <el-submenu :index="menu.name" :key="menu.name" v-else>
          <template slot="title">
            <icon-svg v-if="menu.icon" :icon-class="menu.icon" />
            <span>{{ getTitle(menu) }}</span>
          </template>

          <template v-for="subMenu in menu.list">
            <el-menu-item
              @click="routerClick(subMenu.name, menu.title)"
              :key="subMenu.name"
              :index="subMenu.name"
            >
              <i v-if="subMenu.icon" :class="subMenu.icon"></i>
              <router-link :to="{ name: subMenu.name }">
                {{ getTitle(subMenu) }}
                <!-- {{ subMenu.title }} -->
              </router-link>
            </el-menu-item>
          </template>
        </el-submenu>
      </template>
    </el-menu>
  </el-aside>
</template>

<script>
export default {
  data() {
    return {
      openKeys: []
    }
  },
  computed: {
    headerLink() {
      const arr = JSON.parse(JSON.stringify(this.$store.state.headerLink))
      arr.unshift({
        icon: 'icondaohang_shouye',
        list: [],
        name: 'home',
        title: '首页'
      })
      return arr
    },
    currentPage() {
      return this.$store.state.currentPage
    }
  },
  created() {
    // this.openKeys = this.headerLink
    //   .find(item => item.id === this.currentPage)
    //   .filter(item => item.list && item.list.length)
    //   .map(item => item.key)
  },
  methods: {
    routerClick(name, title) {
      this.$router.push({ name })
    },
    getTitle(menu) {
      let item = menu.title
      return this.$t('basicsData.' + item)
    },
    handleOpen() {},
    handleClose() {}
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-submenu__icon-arrow {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  right: 30px;
}
.asibe {
  display: flex;
  height: calc(100%);
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  .svg-icon {
    width: 20px;
    height: 20px;
  }

  .menu {
    height: 100%;
    background: #515151;
    border-right: none;
  }
  ::v-deep .el-submenu {
    .el-menu-item {
      a {
        padding-left: 8px;
      }
    }
    .el-submenu__title span {
      margin-left: 10px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: 120px;
      // font-size: 16px;
    }
    &.is-active {
      & > .el-submenu__title span {
        font-weight: bold;
        color: #074d29;
      }
    }
  }
  .el-menu-item {
    a {
      color: #333;
    }

    &:hover {
      background: #f6f6f6 !important;
      a {
        color: #074d29;
        font-weight: bold;
      }
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 6px;
        height: 100%;
        background: #074d29;
      }
    }
    &.is-active {
      background: #f6f6f6 !important;
      a {
        color: #074d29;
        font-weight: bold;
      }
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 6px;
        height: 100%;
        background: #074d29;
      }
    }
  }
  .home-item {
    display: flex;
    align-items: center;
    text-indent: 9px;

    a {
      color: #333;
    }
    &.is-active {
      .svg-icon {
        color: #074d29;
      }
    }
  }
}
.en-asibe {
  width: 280px;
}
.imgWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 33px 0 24px;
  .logo {
    height: 30px;
    vertical-align: middle;
  }
  .text {
    color: #074d29;
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;
  }
}
</style>

<template>
  <div id="app" v-if="$route.name">
    <template v-if="['login', 'remoteSd'].includes($route.name)">
      <router-view />
    </template>
    <!-- <layout-container v-else>
      <LayoutHeader slot="header" />
      <router-view />
    </layout-container> -->

    <template v-else>
      <component :is="oldStyle ? 'LayoutContainer' : 'LayoutContainerNew'">
        <template slot="header">
          <LayoutHeader v-if="oldStyle" />
          <LayoutHeaderNew v-else />
        </template>

        <layout-slideMenu slot="slideMenu" />

        <!-- <router-view /> -->

        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
      </component>
    </template>
  </div>
</template>

<script>
import LayoutContainer from './Container'
import LayoutContainerNew from './ContainerNew'
import LayoutHeader from './Header'
import LayoutHeaderNew from './HeaderNew'
import LayoutSlideMenu from './SlideMenu'

export default {
  name: 'App',
  data() {
    return {
      currentContainer: 'LayoutContainerNew'
    }
  },
  components: {
    LayoutHeader,
    LayoutHeaderNew,
    LayoutContainer,
    LayoutContainerNew,
    LayoutSlideMenu
  },
  computed: {
    oldStyle() {
      return sessionStorage.getItem('oldStyle')
    }
  },
  created() {
    if (location.pathname.includes('admin.html')) {
      document.title = this.$t('loginPage.sdyPlatform')
    } else {
      document.title = this.$t('loginPage.customerPlatform')
    }
  }
}
</script>

<style lang="scss">
#app {
  a {
    text-decoration: none;
    user-select: none;
  }
  min-width: 1280px;
  width: 100%;
  height: 100%;
  .page-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 100001;
    background-color: #fff;
  }
  .el-table {
    .cell {
      word-break: break-word;
    }
  }
  .el-form-item__label {
    word-break: break-word;
  }
  .el-form-item__error {
    position: initial;
    margin-top: 5px;
  }
  // .page-loading-cnt {
  //   height: 30%;
  // }

  // .page-loading-circle {
  //   width: 60px;
  //   height: 60px;
  //   margin: 0 auto;
  //   border: 6px solid rgb(243, 243, 243);
  //   border-radius: 100%;
  //   box-sizing: border-box;

  //   .keyframes(@name; @arguments) {
  //     @keyframes @name {
  //       @arguments();
  //     }
  //   }

  //   .keyframes(loading-circle; {0% {transform: rotate3d(0, 0, 0, 0) ;} 100% {transform: rotate3d(0, 1, 0, 180deg) ;}});

  //   animation: loading-circle 0.3s infinite linear;
  // }

  // .page-loading-desc {
  //   margin: 16px 0 0;
  //   text-align: center;
  //   line-height: 1;
  //   color: rgb(172, 172, 172);
  // }
}
</style>

<template>
  <div class="page-com">
    <h2 v-if="title || pageTitle">{{ title || pageTitle }}</h2>
    <slot name="pageTop"></slot>
    <header
      :class="{ borderBottom: borderBottom }"
      v-if="$slots.headerLeft || $slots.headerRight"
      class="containHeader"
    >
      <slot name="headerLeft"></slot>
      <slot name="headerRight"></slot>
    </header>

    <div class="body-content">
      <slot></slot>
    </div>

    <div class="page-footer" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>

    <div class="pagination" v-if="$slots.pagination">
      <slot name="pagination"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page',
  data() {
    return {}
  },
  props: ['borderBottom', 'pageTitle'],
  computed: {
    title() {
      return this.$route.meta.title
    }
  }
}
</script>

<style lang="scss" scoped>
.page-com {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  h2 {
    font-size: 16px;
    text-align: left;
    margin-bottom: 32px;
    color: #515151;
  }

  .body-content {
    flex: 1;
    display: flex;
    height: 0;
    flex-direction: column;
    // ::v-deep .el-table {
    //   flex: 1;
    //   height: 0;
    //   overflow: hidden;
    // }
  }
}
.containHeader {
  display: flex;
  justify-content: space-between;
  margin: 15px 0px;
  // & > div {
  //   &:first-child {
  //     margin-right: 10px;
  //   }
  // }
  & > div > :first-child {
    margin-right: 10px;
  }
  & > div {
    * {
      margin-top: 4px;
    }
    display: flex;
    flex-wrap: wrap;
    ::v-deep .el-input {
      width: 190px;
    }
    * + * {
      // margin-left: 10px;
      margin-left: 0px;
      margin-right: 10px;
    }
    & + div {
      margin-left: 10px;
    }
  }
}

.spanItem {
  cursor: pointer;
  margin-right: 20px;
}
.active {
  color: #074d29;
  border-bottom: 1px solid #074d29;
  display: inline-block;
  height: 30px;
}

.common-tab {
  .spanItem {
    margin-right: 30px;
  }
}

.borderBottom,
.common-tab {
  border-bottom: 1px solid #f5f7fa;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}
.page-footer {
  position: fixed;
  bottom: 90px;
  z-index: 100;
  height: 0;
  width: calc(100% - 280px);
  right: 0;
  text-align: center;
}
</style>

<template>
  <div class="wrap">
    <div class="wrap-ech">
      <v-chart :options="opt" autoresize />
    </div>
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'

export default {
  props: ['chartData'],
  components: {
    VChart: VChart
  },
  data() {
    return {
      opt: {
        tooltip: {
          trigger: 'item'
        },
        // legend: {
        //   orient: 'vertical',
        //   left: 'left'
        // },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: [
              { value: 1048, name: 'Search Engine' },
              { value: 735, name: 'Direct' },
              { value: 580, name: 'Email' },
              { value: 484, name: 'Union Ads' },
              { value: 300, name: 'Video Ads' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }
  },
  watch: {
    chartData(val) {
      // if (val && val.length) {
      //   const legend = []
      //   const data = []
      //   val.forEach(item => {
      //     legend.push(item.modelName || '-')
      //     data.push({
      //       name: item.modelName || '-',
      //       value: item.deviceNumber
      //     })
      //   })
      //   this.opt.legend.data = legend
      //   this.opt.series.data = data
      // }
    }
  }
}
</script>

<style scoped lang="scss">
.wrap {
  position: relative;
  height: 100%;

  h3 {
    font-weight: bold;
    color: #303133;
    font-size: 16px;
    padding: 10px 20px;
  }
  .wrap-ech {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    .echarts {
      flex: 1;
      width: 100%;
    }
  }
}
</style>

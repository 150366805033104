//文字超出隐藏显示...鼠标滑动显示全部
<template>
  <el-tooltip
    class="item"
    effect="dark"
    placement="top"
    :content="content"
    :disabled="isTextOverflowing"
  >
    <div class="text-overflow" ref="textContainer">{{ content }}</div>
  </el-tooltip>
</template>

<script>
export default {
  name: 'CEllipsis',
  props: ['content'],
  data() {
    return {
      isTextOverflowing: false
    }
  },
  mounted() {
    this.setIsTextOverflowing()
  },
  watch: {
    content(newValue, oldValue) {
      if (newValue != oldValue) {
        this.setIsTextOverflowing()
      }
    }
  },
  methods: {
    setIsTextOverflowing() {
      this.$nextTick(() => {
        const container = this.$refs.textContainer
        if (container) {
          this.isTextOverflowing = container.scrollWidth <= container.offsetWidth
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
</style>

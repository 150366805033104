<template>
  <el-container class="container-wrap">
    <slot name="header"></slot>
    <el-container class="container">
      <slot v-if="$route.name === 'home'"></slot>

      <el-main v-else>
        <template v-if="tabs && tabs.length">
          <el-tabs @tab-click="tabClick" type="border-card" v-model="currentTab">
            <el-tab-pane
              :key="item.name"
              v-for="item in tabs"
              :label="item.title"
              :name="item.name"
            >
              <slot
                v-if="item.name === $route.name || ($route.name && $route.name.includes(item.name))"
              ></slot>
            </el-tab-pane>
          </el-tabs>
        </template>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      currentTab: 'deviceManagement'
    }
  },
  async created() {
    if (this.$route.name) {
      if (this.$route.name.includes('deviceList')) {
        this.currentTab = 'deviceList'
      } else if (this.$route.name.includes('processProgressOta')) {
        this.currentTab = 'processProgressOta'
      } else if (this.$route.name.includes('processProgress')) {
        this.currentTab = 'processProgress'
      } else {
        this.currentTab = this.$route.name
      }
    }
  },
  computed: {
    link() {
      return this.$store.state.headerLink.find(item => item.name === this.currentPage)
    },
    tabs() {
      return this.link ? this.link.list : []
    },
    currentPage() {
      return this.$store.state.currentPage
    }
  },
  methods: {
    tabClick(item) {
      this.$router.push({
        name: item.name
      })
    }
  },
  watch: {
    $route(to) {
      if (to.name) {
        if (to.name.includes('deviceList')) {
          this.currentTab = 'deviceList'
        } else if (to.name.includes('processProgressOta')) {
          this.currentTab = 'processProgressOta'
        } else if (to.name.includes('processProgress')) {
          this.currentTab = 'processProgress'
        } else {
          this.currentTab = to.name
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.container-wrap {
  flex-direction: column;

  ::v-deep .el-tabs {
    height: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    .el-tabs__content {
      overflow: auto;
      flex: 1;
      padding: 15px 40px;
      .el-tab-pane {
        height: 100%;
      }
    }

    & > .el-tabs__header .el-tabs__item.is-active,
    & > .el-tabs__header .el-tabs__item:not(.is-disabled):hover {
      color: #86d524;
    }
  }
}
.container {
  display: flex;
  height: calc(100vh - 60px);
  overflow-y: auto;
}

.el-main {
  padding: 20px 120px;
}
</style>

import axios from '../lib/request.js'

const api = {
  /* 项目 */

  //项目列表
  projectList: data => axios.post('/project/page', data),

  //所有项目
  allproject: data => axios.post('/project/list', data),

  //客户列表
  spList: params => axios.get('/sp/listSpInfo', { params }),

  //上传项目
  projectAdd: data => axios.post('/project/add', data),

  //修改项目
  projectUpdate: data => axios.post('/project/update', data),

  //删除项目
  projectDelete: params => axios.get('/project/delete', { params }),

  /* 版本管理 */

  //版本列表
  versionList: data => axios.post('/project/version/page', data),

  //根据项目ID查询所有项目版本
  listVersionByProjectId: params =>
    axios.get('/project/version/listVersionByProjectId', { params }),

  // 版本更新
  versionUpdate: data => axios.post('/project/version/update', data),

  //删除项目版本
  versionDelete: params => axios.get('/project/version/delete', { params }),

  /* 推送升级 */

  //固件版本发布
  versionPublish: data =>
    axios.post('/publish/version/versionPublish', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }),

  /* 升级进度 */

  // 发布列表
  publishList: data => axios.post('/publish/version/listVersionPublish', data),

  // 根据固件发布ID查询设备列表
  pageDeviceByVersionPublishId: data =>
    axios.post('/publish/version/pageDeviceByVersionPublishId', data),

  // 根据固件版本ID查询服务商设备
  listDeviceNumByVersionId: params =>
    axios.get('/project/version/listDeviceNumByVersionId', { params }),

  //根据固件发布ID及推送状态导出设备列表
  downloadList: params =>
    axios.get('/publish/version/exportDeviceListByStatus', { params, responseType: 'blob' })
}

export default api

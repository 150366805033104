import drag from './dragDialog'

export default Vue => {
  Vue.directive('elDragDialog', {
    inserted: function(el, bind) {
      drag(el)
    },
    update(el, bind) {
      if (bind.value && !bind.oldValue) {
        const dialog = el.querySelector('.el-dialog')
        dialog.style.left = 0
        dialog.style.top = 0
      }
    }
  })
  Vue.directive('scroll', {
    inserted: (el, bind, vnode) => {
      el.addEventListener('scroll', function() {
        // console.log('绑定元素的高度：', el.clientHeight)
        // console.log('绑定元素的滚动条的高度', el.scrollHeight)
        // console.log('滚动条距离顶部的距离', el.scrollTop)
        // console.log('滚动条的高度 - 元素的高度 - 滚动条距离顶部的距离 = 0', el.scrollHeight - el.clientHeight - el.scrollTop)
        // 这里的判断和上面watch监听是一样的
        if (el.scrollHeight - el.clientHeight - el.scrollTop <= 0) {
          // binding.value来调用指令上的方法获取数据
          bind.value()
        }
      })
    }
  })
}

<template>
  <div class="wrap">
    <h3>{{$t('adminHomePage.devFirmware')}} (top5)</h3>
    <div class="wrap-ech">
      <v-chart :options="opt" autoresize />
      <div v-if="!chartData.length" class="no-data">{{$t('noData')}}</div>
    </div>
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/chart/bar'

export default {
  props: ['chartData'],

  components: {
    VChart: VChart
  },

  data() {
    const _this = this
    return {
      opt: {
        color: ['#38b8f2'],
        tooltip: {
          trigger: 'axis',
          formatter: data => {
            const target = data[0]
            return target.marker + _this.backupXData[target.dataIndex] + ': ' + target.value + _this.$t('adminHomePage.tower')
          },
          confine: true
        },
        grid: {
          left: '4%',
          right: '4%',
          top: '20%',
          bottom: '6%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: []
        },
        yAxis: {
          name: _this.$t('adminHomePage.unit')+':'+_this.$t('adminHomePage.tower'),
          type: 'value',
          minInterval: 1
        },
        series: {
          name: _this.$t('adminHomePage.devFirmware'),
          type: 'bar',
          barWidth: 20,
          barGap: '30%',
          data: []
        }
      },
      backupXData: []
    }
  },
  watch: {
    chartData(val) {
      if (val && val.length) {
        const xAxisData = []
        const backupXData = []
        const data = []

        const chartData = [...val]

        chartData
          .sort((a, b) => b.deviceNumber - a.deviceNumber)
          .forEach(item => {
            xAxisData.push(item.firmwareVersion ? item.firmwareVersion.slice(0, 4) + '...' : '-')
            backupXData.push(item.firmwareVersion)
            data.push(item.deviceNumber)
          })

        this.backupXData = backupXData

        this.opt.xAxis.data = xAxisData
        this.opt.series.data = data
      }
    }
  }
}
</script>

<style scoped lang="scss">
.wrap {
  position: relative;
  height: 100%;

  h3 {
    font-weight: bold;
    color: #303133;
    font-size: 16px;
    padding: 10px 20px;
  }

  .wrap-ech {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    .echarts {
      flex: 1;
      width: 100%;
    }
  }
}
</style>

import appManagement from './appManagement.js'
import otaManagement from './otaManagement.js'
import deviceManagement from './deviceManagement.js'
import systemManagement from './systemManagement.js'
import statisticalManagement from './statisticalManagement.js'
import marketManagement from './marketManagement.js'
import common from './common.js'

export default {
  appManagement,
  otaManagement,
  deviceManagement,
  systemManagement,
  statisticalManagement,
  marketManagement,
  common
}

<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    :close-on-click-modal="false"
    width="600px"
    :title="$t('changePassword')"
    class="change-password"
    :visible.sync="show"
  >
    <el-form
      ref="password"
      size="small"
      :rules="rules"
      :model="password"
      label-position="right"
    >
      <el-form-item :label="$t('oldPassword')" prop="old_pw">
        <el-input
          class="selfInput"
          :type="invisibleOne ? 'password' : 'text'"
          v-model.trim="password.old_pw"
        >
          <icon-svg
            v-if="password.old_pw"
            :icon-class="invisibleOne ? 'iconbukejian' : 'iconkejian'"
            slot="suffix"
            @click="invisibleOne = !invisibleOne"
          />
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('newPassword')" prop="new_pw1">
        <el-input
          class="selfInput"
          :type="invisibleTwo ? 'password' : 'text'"
          v-model.trim="password.new_pw1"
        >
          <icon-svg
            v-if="password.new_pw1"
            :icon-class="invisibleTwo ? 'iconbukejian' : 'iconkejian'"
            slot="suffix"
            @click="invisibleTwo = !invisibleTwo"
          />
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('confirmationNewPassword')" prop="new_pw2">
        <el-input
          class="selfInput"
          @keyup.native.enter="submitForm"
          :type="invisibleThree ? 'password' : 'text'"
          v-model.trim="password.new_pw2"
        >
          <icon-svg
            v-if="password.new_pw2"
            :icon-class="invisibleThree ? 'iconbukejian' : 'iconkejian'"
            slot="suffix"
            @click="invisibleThree = !invisibleThree"
          />
        </el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{$t('cancel')}}</el-button>
      <el-button type="primary" @click="submitForm">{{$t('confirm')}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  props: ['username'],
  data() {
    const pwValid = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('loginPage.passwordPlaceholder')))
      } else {
        callback()
      }
    }

    const pwValid1 = (rule, value, callback) => {
      const pdTest = /^(?=.*[~!@#$%^&*()_?])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/
      if (value === '') {
        callback(new Error(this.$t('inputNewPassword')))
      } else if (value === this.password.old_pw) {
        callback(new Error(this.$t('newdifferentOld')))
        
      } else {
        if (value === sessionStorage.getItem('username')) {
          callback(new Error(this.$t('passwordDifferentAccount')))
        } else {
          if (!pdTest.test(value)) {
            callback(
              new Error(this.$t('passwordRules'))
            )
          } else {
            callback()
          }
        }
      }
    }

    const pwValid2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('inputNewPassword')))
      } else if (value !== this.password.new_pw1) {
        callback(new Error(this.$t('twicePasswordDifferent')))
      } else {
        callback()
      }
    }
    return {
      currentEl: 0,
      invisibleOne: true,
      invisibleTwo: true,
      invisibleThree: true,
      loading: false,
      password: {
        old_pw: '',
        new_pw1: '',
        new_pw2: ''
      },
      rules: {
        old_pw: [{ validator: pwValid, trigger: 'blur', required: true }],
        new_pw1: [{ validator: pwValid1, trigger: 'blur', required: true }],
        new_pw2: [{ validator: pwValid2, trigger: 'blur', required: true }]
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs.password.validate(async valid => {
        if (valid) {
          this.loading = true

          const sha256 = require('js-sha256').sha256

          const params = this.password

          try {
            await this.$api.systemManagement.userUpdatePassword({
              newPassword: sha256(params.new_pw1 + this.$store.state.privateKey),
              oldPassword: sha256(params.old_pw + this.$store.state.privateKey)
            })
            this.$refs.password.resetFields()
            this.$message.success(this.$t('passwordModifiedSuccess'))
            this.$emit('update:visible', false)
            setTimeout(() => {
              this.$emit('logout')
            }, 1000)
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.svg-icon {
  cursor: pointer;
}
::v-deep .el-form-item.is-error {
  margin-bottom: 32px;
}
</style>

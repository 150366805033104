import axios from '../lib/request.js'
const api = {
  //统计设备活跃量
  deviceStatisticByActive: data => axios.post('/device/statistics/deviceStatisticByActive', data),

  //根据应用统计设备安装数量
  installStatisticByApp: data => axios.post('/device/statistics/installStatisticByApp', data),

  //根据固件版本号分组统计设备数量
  deviceStatisticByFirmwareGroup: data =>
    axios.post('/device/statistics/deviceStatisticByFirmwareGroup', data),

  //根据固件版本号统计设备数量
  deviceStatisticByFirmwareVersion: data =>
    axios.post('/device/statistics/deviceStatisticByFirmwareVersion', data),

  //根据系统版本号分组统计设备数量
  deviceStatisticBySystemGroup: data =>
    axios.post('/device/statistics/deviceStatisticBySystemGroup', data),

  //根据应用统计设备发布状态数量
  listDeviceNumByAppId: data => axios.post('/publish/listDeviceNumByAppId', data),

  //补发应用
  reissueApp2Device: data => axios.post('/publish/reissueApp2Device', data),

  //根据发布ID及应用安装状态导出设备列表

  exportDeviceListByAppId: data =>
    axios.post('/publish/exportDeviceListByAppId', data, { responseType: 'blob' }),

  //根据云平台版本号分组统计设备数量
  deviceStatisticByCloudPlatformGroup: data =>
    axios.post('/device/statistics/deviceStatisticByCloudPlatformGroup', data),

  //根据云平台版本号统计设备数量
  deviceStatisticByCloudPlatformVersion: data =>
    axios.post('/device/statistics/deviceStatisticByCloudPlatformVersion', data),

  //根据系统版本号统计设备数量
  deviceStatisticBySystemVersion: data =>
    axios.post('/device/statistics/deviceStatisticBySystemVersion', data),

  //根据设备状态分组统计设备数量
  deviceStatisticByStatusGroup: data =>
    axios.post('/device/statistics/deviceStatisticByStatusGroup', data),

  //根据设备状态统计设备数量
  deviceStatisticByStatus: data => axios.post('/device/statistics/deviceStatisticByStatus', data),

  //根据设备活跃量导出Excel文件
  exportByActive: data =>
    axios.post('/device/statistics/exportByActive', data, { responseType: 'blob' }),

  //根据系统版本号导出Excel文件
  exportBySystemVersion: data =>
    axios.post('/device/statistics/exportBySystemVersion', data, { responseType: 'blob' }),

  //根据设备状态导出Excel文件
  exportByStatus: data =>
    axios.post('/device/statistics/exportByStatus ', data, { responseType: 'blob' }),

  //根据客户获取应用
  listBySpId: data => axios.post('/app/listBySpId', data)
}

export default api

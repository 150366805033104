<template>
  <el-header
    class="header"
    :class="{
      specialHeader: specialHeader
    }"
  >
    <div class="rightPart">
      <!-- <el-link style="margin-right:6px" @click="toOldVersion" type="info">旧版</el-link> -->

      <!-- <div class="spaceWrap" @click="visible2 = true">
        <span class="label">{{$t('spaceCapacity')}}</span>
        <div style="position: relative;">
          <el-progress
            style="width:150px"
            color="#515151"
            :text-inside="false"
            :show-text="false"
            :stroke-width="20"
            :percentage="60"
          />
          <span class="spaceTxt">{{ spaceTxt }}</span>
        </div>
      </div> -->

      <icon-svg icon-class="icontouxiang" class-name="icons" />
      <el-menu
        default-active="1"
        class="el-menu-demo"
        mode="horizontal"
        background-color="#f6f6f6"
        text-color="#666"
        active-text-color="#666"
      >
        <el-submenu index="1">
          <template slot="title">{{ title }}</template>
          <el-menu-item @click="visible = true">{{ $t('changePassword') }}</el-menu-item>
          <el-menu-item @click="logout">{{ $t('signOut') }}</el-menu-item>
        </el-submenu>
        <span v-if="title" class="daysRemaining">{{ daysRemaining }}</span>
      </el-menu>
    </div>
    <password-modal @logout="logout" :visible.sync="visible" v-if="visible" />
    <space-modal :visible.sync="visible2" v-if="visible2" />
  </el-header>
</template>

<script>
import passwordModal from './components/passwordModal'
import spaceModal from './components/spaceModal'

export default {
  data() {
    return {
      title: '',
      haveMessage: true,
      visible: false,
      visible2: false
    }
  },
  async created() {
    this.innitSocket()

    if (this.username) {
      this.title = this.username
    } else {
      try {
        const res = await this.$api.systemManagement.userCurrent()
        this.title = res.result.accountCode || 'admin'
        this.$store.commit('SET_USERINFO', res.result)
      } catch (e) {
        console.log(e)
      }
    }
  },
  computed: {
    spaceTxt() {
      return '100M/1G'
    },
    username() {
      return this.$store.state.username
    },
    specialHeader() {
      return (
        this.$route.name === 'home' &&
        !(window.location && window.location.pathname.includes('/admin'))
      )
    },
    currentPage() {
      return this.$store.state.currentPage
    },
    daysRemaining() {
      return sessionStorage.getItem('daysRemaining')
    },
    headerLink() {
      const arr = JSON.parse(JSON.stringify(this.$store.state.headerLink))

      arr.unshift({
        list: [],
        name: 'home',
        title: this.$t('slideMenu.home')
      })
      return arr
    }
  },
  components: {
    passwordModal,
    spaceModal
  },
  methods: {
    toOldVersion() {
      sessionStorage.setItem('oldStyle', true)
      location.reload()
    },
    innitSocket() {
      // var token = this.$utils.getCookie('loh-auth-token')
      // var api = `ws://${BASE_URL}/lohsvc/real-time?token=${token}`
      // var ws = new WebSocket(api)
      // ws.onopen = e => {
      //   console.log('websocket has connected!')
      // }
      // ws.onmessage = e => {
      //   console.log(e.data)
      // }
      // ws.onclose = e => {
      //   console.log('websocket has disconnected!')
      // }
      // ws.onerror = e => {
      //   console.log(e)
      // }
    },
    async logout() {
      try {
        const res = await this.$api.common.logout()

        if (res.code === 'OK') {
          localStorage.clear()
          sessionStorage.clear()
          this.$store.state.headerLink = []
          this.$router.push('/login')
          this.$store.commit(`SET_HEADER_TABS`, [])
          // console.log('headerTabs', this.$store.state.headerTabs)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: flex-end;

  .rightPart {
    display: flex;
    align-items: center;

    .spaceWrap {
      margin-right: 6px;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;

      ::v-deep .el-progress-bar__outer {
        background-color: #afafaf;
      }

      .label {
        color: #515151;
        margin-right: 6px;
      }

      .spaceTxt {
        position: absolute;
        left: 5px;
        top: 2px;
        font-size: 12px;
        font-weight: bold;
        color: #fff;
      }
    }

    .alertWrap {
      margin-right: 30px;
      cursor: pointer;
    }

    .icons {
      width: 32px;
      height: 32px;
    }

    .iconalert {
      width: 20px;
      height: 20px;
      color: #fff;

      &:hover {
        color: #46adff;
      }
    }

    ::v-deep .el-submenu__title {
      padding: 0 0 0 11px;
      border-bottom: 0;
      i {
        color: #ccc;
        margin-top: -2px;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }

  ::v-deep .el-menu.el-menu--horizontal {
    border-bottom: 0;
    position: relative;
    top: -6px;

    .daysRemaining {
      position: absolute;
      bottom: 5px;
      font-size: 12px;
      color: #074d29;
      right: 12px;
      width: 150px;
      text-align: right;
    }
  }

  ::v-deep .el-submenu .el-submenu__title {
    border-bottom-color: transparent;
    color: rgb(102, 102, 102);
    background-color: rgb(246, 246, 246) !important;
  }
}
.specialHeader {
  padding: 0;
  background: #f6f6f6;
  ::v-deep .el-submenu .el-submenu__title {
    background-color: #f6f6f6 !important;
    // color: #fff !important;
  }

  .spaceWrap {
    .label {
      color: #fff !important;
    }
  }
}
</style>
